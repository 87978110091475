$(document).ready(function () {
	function initializeVideoModal() {
		const modal = document.getElementById("kalski-team-modal");
		const modalContent = document.getElementsByClassName(
			"kalski-team-modal-content"
		);
		const videoPlayer = document.getElementById("modalVideoPlayer");
		const videoSource = document.getElementById("videoSource");
		const span =
			document.getElementsByClassName("kalski-team-close")[0];

		if (
			!modal ||
			!modalContent ||
			!videoPlayer ||
			!videoSource ||
			!span
		)
			return;

		function openModal(videoSrc) {
			videoPlayer.style.width = "auto";
			videoPlayer.style.height = "100%";

			document.querySelector("body").style.overflowY = "hidden";

			videoSource.src = videoSrc;
			videoPlayer.load();
			videoPlayer.play();
			modal.style.display = "flex";
			modalContent[0].style.opacity = "0";
			modalContent[0].style.animation = "fadeIn 0.5s forwards";
		}

		span.onclick = function () {
			modal.style.display = "none";
			videoPlayer.pause();
			videoPlayer.currentTime = 0;
			document.querySelector("body").style.overflowY = "auto";
		};

		window.onclick = function (event) {
			if (event.target == modal) {
				modal.style.display = "none";
				videoPlayer.pause();
				videoPlayer.currentTime = 0;
				document.querySelector("body").style.overflowY = "auto";
			}
		};

		document
			.querySelectorAll(".hero-columns div")
			.forEach(function (element) {
				element.addEventListener("click", function () {
					const videoSrc =
						this.getAttribute("data-video-src");
					openModal(videoSrc);
				});
			});
	}

	initializeVideoModal();
});
