$(document).ready(function () {
	const scatteredLetters = () => {
		gsap.registerPlugin(ScrollTrigger);

		if (window.innerWidth < 1200) return;

		Splitting({
			target: ".animated-letters.selling-skills__desktop p",
			by: "chars",
		});
		const chars = document.querySelectorAll(".char");

		if (!chars || chars.length < 1) return;

		chars.forEach((char) => {
			const randomX =
				Math.random() * window.innerWidth -
				window.innerWidth / 2;
			const randomY =
				Math.random() * window.innerHeight -
				window.innerHeight / 2;
			const randomRotation = Math.random() * 90 - 70;

			gsap.set(char, {
				x: randomX,
				y: randomY,
				rotation: randomRotation,
			});

			char.dataset.initialX = randomX;
			char.dataset.initialY = randomY;
			char.dataset.initialRotation = randomRotation;
		});

		gsap.set(".btn-wrapper", {
			y: 500,
			autoAlpha: 0,
		});

		const tlLetters = gsap.timeline({
			scrollTrigger: {
				trigger: ".selling-skills",
				start: "80% 70%",
				end: "100% 20%",
				scrub: 2,
				pin: true,

				// markers: true,
			},
		});

		chars.forEach((char) => {
			tlLetters.to(
				char,
				{
					x: 0,
					y: 0,
					rotation: 0,
					duration: 1,
				},
				0
			);
		});

		tlLetters.to(
			".btn-wrapper",
			{
				y: 0,
				autoAlpha: 1,
				duration: 1,
				ease: "power4.out",
			}
		);
	};

	scatteredLetters();
});
