$(document).ready(function () {
	const kalskiTeamTestimonials = () => {
		const swiper = new Swiper(".swiper", {
			slidesPerView: 1,
			effect: "slide",
			loop: true,
			pagination: {
				el: ".swiper-pagination",
				clickable: true,
			},
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			},
		});
	};

	kalskiTeamTestimonials();
});
