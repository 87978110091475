$(document).ready(function () {
	const revealAnimationOffer = () => {
		// show stagger childrens of multiple .offer when visible on screen

		gsap.registerPlugin(ScrollTrigger);

		const offers = document.querySelectorAll(".offer:not(.offer.skip)");

		// start animation only on tablet and desktop

		if (!offers || offers.length < 1 || window.innerWidth < 768) return;

		offers.forEach((offer) => {
			const tl = gsap.timeline({
				scrollTrigger: {
					trigger: offer,
					start: "20% 100%",
					end: "50% 70%",
					scrub: 1,

					// markers: true,
				},
			});

			const lines = offer.querySelectorAll(".offer .animate");

			if (!lines || lines.length < 1) return;

			lines.forEach((line) => {
				tl.fromTo(
					line,
					{
						y: 100,
						autoAlpha: 0,
					},
					{
						y: 0,
						autoAlpha: 1,
						duration: 0.3,
						ease: "back.out(2)",
					}
				);
			});
		});
	};

	revealAnimationOffer();
});
