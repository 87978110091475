$(document).ready(function () {
	const iconsRotationOnScroll = () => {
		const baseSrc =
			"https://kalski.com.pl/wp-content/themes/kalskiTeam/";

		const icons = [
			{
				element: document.querySelector(
					".why-us .why-us-container .why-us-card:nth-child(1) img"
				),
				sources: [
					baseSrc + "assets/img/square-bottom.svg",
					baseSrc + "assets/img/square-left.svg",
					baseSrc + "assets/img/square-top.svg",
					baseSrc + "assets/img/square-right.svg",
				],
			},
			{
				element: document.querySelector(
					".why-us .why-us-container .why-us-card:nth-child(2) img"
				),
				sources: [
					baseSrc + "assets/img/square-right.svg",
					baseSrc + "assets/img/square-bottom.svg",
					baseSrc + "assets/img/square-left.svg",
					baseSrc + "assets/img/square-top.svg",
				],
			},
			{
				element: document.querySelector(
					".why-us .why-us-container .why-us-card:nth-child(3) img"
				),
				sources: [
					baseSrc + "assets/img/square-top.svg",
					baseSrc + "assets/img/square-right.svg",
					baseSrc + "assets/img/square-bottom.svg",
					baseSrc + "assets/img/square-left.svg",
				],
			},
			{
				element: document.querySelector(
					".why-us .why-us-container .why-us-card:nth-child(4) img"
				),
				sources: [
					baseSrc + "assets/img/square-left.svg",
					baseSrc + "assets/img/square-top.svg",
					baseSrc + "assets/img/square-right.svg",
					baseSrc + "assets/img/square-bottom.svg",
				],
			},
		];

		if (icons.some((icon) => !icon.element)) return;

		icons.forEach((icon) => {
			let currentIndex = 0;
			let lastProgress = 0;

			ScrollTrigger.create({
				trigger: document.querySelector(".why-us"),
				start: "top 80%",
				end: "bottom 20%",

				onUpdate: (self) => {
					const progress = Math.floor(self.progress * 8);

					if (progress !== lastProgress) {
						currentIndex =
							(currentIndex + 1) %
							icon.sources.length;
						icon.element.src =
							icon.sources[currentIndex];
						lastProgress = progress;
					}
				},
				onLeave: () => {
					currentIndex = 0;
					icon.element.src = icon.sources[currentIndex];
				},
				onLeaveBack: () => {
					currentIndex = 0;
					icon.element.src = icon.sources[currentIndex];
				},
			});
		});
	};

	gsap.registerPlugin(ScrollTrigger);
	iconsRotationOnScroll();
});
