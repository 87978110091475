$(document).ready(function () {
	const initStickyAndHighlight = () => {
		const offerNav = document.querySelector(".offer-nav__slide");

		if (!offerNav) return;

		const sticky = () => {
			if (window.pageYOffset > offerNav.offsetTop + 450) {
				offerNav.classList.add("is-sticky");
			} else {
				offerNav.classList.remove("is-sticky");
			}
		};

		window.addEventListener("scroll", sticky);

		gsap.registerPlugin(ScrollTrigger);

		const sections = document.querySelectorAll("section.offer");
		const menuLinks = document.querySelectorAll(".offer-nav__item a");

		if (!sections.length || !menuLinks.length) return;

		sections.forEach((section) => {
			ScrollTrigger.create({
				trigger: section,
				start: "top center",
				end: "bottom center",
				// markers: true,
				onEnter: () => setActiveLink(section.id),
				onEnterBack: () => setActiveLink(section.id),
				onLeave: () => setActiveLink(null),
				onLeaveBack: () => setActiveLink(null),
			});
		});

		function setActiveLink(sectionId) {
			menuLinks.forEach((link) => {
				link.classList.toggle(
					"active",
					sectionId &&
						link.getAttribute("href") ===
							`#${sectionId}`
				);
			});
		}
	};

	initStickyAndHighlight();
});
