$(document).ready(function () {
	const playButton = () => {
		const videoCotainers =
			document.querySelectorAll(".video-container");

		videoCotainers.forEach((videoCotainer) => {
			const playButton =
				videoCotainer.querySelector(".play-button");

			const videoPlayer = videoCotainer.querySelector("video");

			if (!playButton || !videoPlayer) return;

			playButton.addEventListener("click", function () {
				if (videoPlayer.paused) {
					videoPlayer.play();
					videoPlayer.setAttribute("controls", "controls");
					playButton.classList.add("hidden");
				} else {
					videoPlayer.pause();
					videoPlayer.removeAttribute("controls");
					playButton.classList.remove("hidden");
				}
			});

			videoPlayer.addEventListener("click", function () {
				if (videoPlayer.paused) {
					videoPlayer.play();
					videoPlayer.setAttribute("controls", "controls");
					playButton.classList.add("hidden");
				} else {
					videoPlayer.pause();
					videoPlayer.removeAttribute("controls");
					playButton.classList.remove("hidden");
				}
			});
		});
	};

	
	playButton();
});
