$(document).ready(function () {
	const animateOnScroll = () => {
		gsap.registerPlugin(ScrollTrigger);

		Splitting({ target: ".kalski-offer .animate", by: "lines" });

		const lines = document.querySelectorAll(".lines.animate");

		if (!lines || lines.length < 1) return;

		const tl = gsap.timeline({
			scrollTrigger: {
				trigger: ".kalski-offer",
				start: "top 60%",
				end: "80% 60%",
				scrub: 1,
				// markers: true,
			},
		});

		lines.forEach((line) => {
			tl.fromTo(
				line,
				{
					y: 100,
					autoAlpha: 0,
				},
				{
					y: 0,
					autoAlpha: 1,
					duration: 1,
					ease: "back.out(1)",
				}
			);
		});
	};

	animateOnScroll();
});
