$(document).ready(function () {
  const writingTextAnimation = () => {
    const textArea = document.querySelector('.wpcf7-textarea');

    if (!textArea) return;

    const sentences = [
      'Proszę o kontakt, od jakiegoś czasu wyniki handlowe zatrzymały się, potrzebujemy…',
      'Mam pewną “zagadkę handlową” dotyczącą strategii sprzedaży naszych produktów, potrzebuję…',
      'Mój zespół handlowy od roku zatrzymał się w rozwoju, nie jestem pewien z czego to wynika, chciałbym…',
    ];

    let currentSentence = 0;
    let isUserInteracting = false;
    let userTyped = false;

    const writeText = (textArea, sentence) => {
      textArea.value = '';
      let i = 0;
      const interval = setInterval(() => {
        if (isUserInteracting) {
          clearInterval(interval);
          return;
        }
        textArea.value += sentence[i];
        i++;
        if (i === sentence.length) {
          clearInterval(interval);
          setTimeout(() => {
            deleteText(textArea, sentence);
          }, 1000);
        }
      }, 50);
    };

    const deleteText = (textArea, sentence) => {
      let i = sentence.length;
      const interval = setInterval(() => {
        if (isUserInteracting) {
          clearInterval(interval);
          return;
        }
        textArea.value = sentence.slice(0, i);
        i--;
        if (i < 0) {
          clearInterval(interval);
          currentSentence = (currentSentence + 1) % sentences.length;
          setTimeout(() => {
            writeText(textArea, sentences[currentSentence]);
          }, 1000);
        }
      }, 30);
    };

    textArea.addEventListener('focus', () => {
      if (!userTyped) {
        isUserInteracting = true;
        gsap.killTweensOf(textArea);
        textArea.value = '';
      }
    });

    textArea.addEventListener('input', () => {
      isUserInteracting = true;
      userTyped = true;
    });

    textArea.addEventListener('blur', () => {
      isUserInteracting = false;
      if (!textArea.value) {
        userTyped = false;
        writeText(textArea, sentences[currentSentence]);
      }
    });

    writeText(textArea, sentences[currentSentence]);
  };

  writingTextAnimation();
});