$(document).ready(function() {
  const toggleMenu = () => {
    const menuToggle = $(".menu-toggle");
    const hamburgerBtn = $(".menu-toggle .hamburger");

    if (!menuToggle.length || !hamburgerBtn.length) return;

    hamburgerBtn.on("click", function () {
      menuToggle.toggleClass("active");
      hamburgerBtn.toggleClass("active");
      $("body").toggleClass("menu-open");
    });
  };

  // when clicked on menu item close menu
  const menuItems = $(".menu-item");
  menuItems.on("click", function() {
    $(".menu-toggle").removeClass("active");
    $(".menu-toggle .hamburger").removeClass("active");
    $("body").removeClass("menu-open");
  });

  toggleMenu();
});
